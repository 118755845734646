import { useEffect, useState } from "react"

import { isInEurope } from "src/components/Account/BillingPortal/countryData"
import { CurrencyCode, ICustomer } from "src/components/Account/types"
import { useFetchCurrentSubscription } from "src/data/billing/queries/billingQueries"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { fetchGeoLocationData } from "src/utils/fetchGeoLocationData"

export function useCustomerCurrency({
  customer,
  loading,
}: {
  customer: ICustomer | null | undefined
  loading: boolean
}) {
  const { user_id } = useGetUser()
  const fetchSubscription = useFetchCurrentSubscription({
    userId: user_id,
  })
  const subscription = fetchSubscription.data
  const loadingSubscription = fetchSubscription.isLoading

  const [currencyCode, setCurrencyCode] = useState(subscription?.currency_code)

  // Determine currency
  useEffect(() => {
    // We only determine the currency with this logic once we have fetched
    // the current subscription (if any)
    if (loadingSubscription) {
      return
    }

    // Select currency of existing plan if it exists
    if (subscription) {
      setCurrencyCode(subscription.currency_code)
      return
    }

    // Otherwise select currency based on billing country
    const country = customer?.billing_address?.country
    if (country) {
      setCurrencyCode(mapCountryToCurrency(country))
      return
    }

    // If none of the above is true, select based on geo
    fetchGeoLocationData().then((d) => {
      setCurrencyCode(mapCountryToCurrency(d.countryCode))
    })
  }, [customer, loading, subscription, loadingSubscription])

  return currencyCode
}

/**
 * This list should map to how the currencies are set in the store/checkout,
 * as currencies currently need to match for it to be possible to place an
 * order for customers, furthermore the currencies in the store/checkout is
 * limited by the base currency set for the specific Shopify store that can
 * deliver to that region currently, hence it might not always be what you
 * would expect */
function mapCountryToCurrency(countryCode?: string) {
  switch (countryCode?.toUpperCase()) {
    case "JE":
    case "GB": {
      return CurrencyCode.GBP
    }
    case "SE": {
      return CurrencyCode.SEK
    }
    case "CA": {
      return CurrencyCode.CAD
    }
    case "AU":
    case "NZ": {
      return CurrencyCode.AUD
    }
    case "AR":
    case "CL":
    case "GT":
    case "HK":
    case "IN":
    case "JP":
    case "MX":
    case "PE":
    case "SG":
    case "TH":
    case "TW":
    case "VN":
    case "US": {
      return CurrencyCode.USD
    }
    case "AD":
    case "AE":
    case "AT":
    case "AX":
    case "BA":
    case "BE":
    case "BG":
    case "CH":
    case "CY":
    case "CZ":
    case "DE":
    case "DK":
    case "EE":
    case "EG":
    case "ES":
    case "FI":
    case "FO":
    case "FR":
    case "GI":
    case "GR":
    case "HR":
    case "HU":
    case "IE":
    case "IL":
    case "IS":
    case "IT":
    case "LI":
    case "LT":
    case "LU":
    case "LV":
    case "MA":
    case "MC":
    case "MT":
    case "NL":
    case "NO":
    case "PL":
    case "PT":
    case "QA":
    case "RO":
    case "RS":
    case "SI":
    case "SK":
    case "SM":
    case "TR":
    case "UA":
    case "VA":
    case "ZA": {
      return CurrencyCode.EUR
    }
    default: {
      if (isInEurope(countryCode)) {
        return CurrencyCode.EUR
      }
      return CurrencyCode.USD
    }
  }
}
