import styled from "styled-components"

import { SelectedPlan } from "src/components/Account/BillingPortal/ChangePlan/ChangePlan"
import {
  getUpdateOptions,
  PlanChangeTypeV2,
  planToCopy,
} from "src/components/Account/BillingPortal/ChangePlan/utils"
import { usePostSubscription } from "src/data/billing/queries/billingQueries"
import { TCurrentSubscription } from "src/data/billing/types/billingTypes"
import { usePatchUserSubscription } from "src/data/subscription/queries/subscriptionQueries"
import { useHomeTokens } from "src/hooks/useHomeTokens"
import { useTranslate } from "src/i18n/useTranslate"
import { MButtonLegacy } from "src/ui/Button/MButtonLegacy"
import { MBanner } from "src/ui/MBanner/MBanner"
import { spacing } from "src/ui/spacing"

export function UpdateSubscriptionButton({
  selectedPlan,
  planChangeType,
  subscription,
  onSuccess,
  disabled,
  couponIds,
}: {
  selectedPlan: SelectedPlan | undefined
  planChangeType: PlanChangeTypeV2 | undefined
  subscription: TCurrentSubscription
  onSuccess: () => void
  disabled?: boolean
  couponIds: string[] | undefined
}) {
  const { t, langKeys } = useTranslate()
  const { hasHomeTokenRole } = useHomeTokens()
  const postSubscription = usePostSubscription()
  const patchSubscription = usePatchUserSubscription()

  function updateSubscription(
    planId: SelectedPlan["planId"],
    planChangeType: PlanChangeTypeV2
  ) {
    if (hasHomeTokenRole) {
      const body = { new_plan_id: planId, coupon_ids: couponIds }
      return patchSubscription.mutate(
        { subscriptionId: subscription.subscription_id, body },
        { onSuccess }
      )
    }

    const updateOptions = getUpdateOptions({ planChangeType })
    postSubscription.mutate(
      {
        subscriptionId: subscription.subscription_id,
        subscriptionBody: {
          plan_id: planId,
          replace_addon_list: false,
          coupon_ids: couponIds,
          ...updateOptions,
        },
      },
      {
        onSuccess,
      }
    )
  }

  if (
    planChangeType === PlanChangeTypeV2.UNCHANGED ||
    !selectedPlan ||
    !planChangeType
  ) {
    return (
      <MButtonLegacy disabled size="large" fullWidth>
        {t(langKeys.change_plan)}
      </MButtonLegacy>
    )
  }

  const isError = hasHomeTokenRole
    ? patchSubscription.isError
    : postSubscription.isError
  const isLoading = hasHomeTokenRole
    ? patchSubscription.isLoading
    : postSubscription.isLoading

  return (
    <Box>
      {isError && (
        <MBanner type="error">{`${t(langKeys.payment_failed)}. ${t(
          langKeys.payment_failed_help_funds
        )}`}</MBanner>
      )}

      <MButtonLegacy
        size="large"
        loading={isLoading}
        onClick={() => {
          updateSubscription(selectedPlan.planId, planChangeType)
        }}
        fullWidth
        style={{ marginBottom: spacing.S }}
        disabled={!!disabled}
      >
        {t(langKeys.change_plan_to, {
          plan_name: planToCopy(selectedPlan.plan),
        })}
      </MButtonLegacy>
    </Box>
  )
}

const Box = styled.div`
  display: grid;
  gap: ${spacing.M};
`
