import styled from "styled-components"

import { IDiscount, TDiscount } from "src/components/Account/types"
import { TSubscriptionCurrencyCode } from "src/data/billing/types/billingTypes"
import { colorsV2 } from "src/ui/colors"
import { formatPrice } from "src/utils/formatPrice"

import { LineItem } from "./LineItem"

export function DiscountsLineItem({
  discounts,
  currencyCode,
}: {
  discounts: IDiscount[] | TDiscount[]
  currencyCode: TSubscriptionCurrencyCode
}) {
  return (
    <div>
      {discounts.map((d) => (
        <LineItem
          key={d.description}
          description={d.description}
          value={
            <DiscountColor>
              -{formatPrice((d?.amount ?? 0) / 100, currencyCode)}
            </DiscountColor>
          }
        />
      ))}
    </div>
  )
}

const DiscountColor = styled.div`
  color: ${colorsV2.systemGoodDark};
`
