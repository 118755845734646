import { Dispatch, SetStateAction } from "react"

import { SelectedPlan } from "src/components/Account/BillingPortal/ChangePlan/ChangePlan"
import { CreateSubscriptionButton } from "src/components/Account/BillingPortal/ChangePlan/CreateSubscriptionButton"
import { OrderSummary } from "src/components/Account/BillingPortal/ChangePlan/OrderSummary/OrderSummary"
import { PlanSelect } from "src/components/Account/BillingPortal/ChangePlan/PlanSelect"
import {
  getCouponIdsFromEstimate,
  PlanChangeTypeV2,
  PlanWithCostEstimate,
} from "src/components/Account/BillingPortal/ChangePlan/utils"
import { IPaymentSource } from "src/components/Account/types"
import { TSubscriptionCurrencyCode } from "src/data/billing/types/billingTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MBanner } from "src/ui/MBanner/MBanner"
import { spacing } from "src/ui/spacing"

export function CreateSubscription({
  selectedPlan,
  setSelectedPlan,
  paymentSource,
  currencyCode,
  plansWithEstimate,
  numberOfHomes,
}: {
  selectedPlan: SelectedPlan | undefined
  setSelectedPlan: Dispatch<SetStateAction<SelectedPlan | undefined>>
  paymentSource: IPaymentSource | undefined
  currencyCode: TSubscriptionCurrencyCode
  plansWithEstimate: PlanWithCostEstimate[]
  numberOfHomes: number | undefined
}) {
  const { t, langKeys } = useTranslate()

  const { navigate } = useRouter()

  const planChangeType = !!selectedPlan
    ? PlanChangeTypeV2.CREATE
    : PlanChangeTypeV2.UNCHANGED

  const selectedPlanWithEstimate = plansWithEstimate.find(
    (plan) => plan.availablePlan.plan_id === selectedPlan?.planId
  )

  return (
    <>
      <PlanSelect
        plans={plansWithEstimate}
        currencyCode={currencyCode}
        selectedPlan={selectedPlan}
        onSelect={(plan) => setSelectedPlan(plan)}
      />

      {!paymentSource && (
        <MBanner
          type="warning"
          style={{ marginBottom: spacing.M, textAlign: "center" }}
        >
          {t(langKeys.change_plan_add_payment_method_first)}
        </MBanner>
      )}

      {selectedPlanWithEstimate?.cost && selectedPlan && (
        <OrderSummary
          planCost={selectedPlanWithEstimate.cost}
          currencyCode={currencyCode}
          selectedPlan={selectedPlan}
          estimate={selectedPlanWithEstimate.cost.estimate.estimate}
        />
      )}

      <CreateSubscriptionButton
        selectedPlan={selectedPlan}
        planChangeType={planChangeType}
        onSuccess={() => navigate(Routes.ChangePlanSuccess.location())}
        disabled={!paymentSource}
        numberOfHomes={numberOfHomes}
        couponIds={getCouponIdsFromEstimate(
          selectedPlanWithEstimate?.cost?.estimate?.estimate
        )}
      />
    </>
  )
}
