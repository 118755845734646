import { Dispatch, SetStateAction, useCallback, useEffect } from "react"

import { Skeleton } from "@material-ui/lab"

import { SelectedPlan } from "src/components/Account/BillingPortal/ChangePlan/ChangePlan"
import { LegacyPlanDisclaimer } from "src/components/Account/BillingPortal/ChangePlan/LegacyPlanDisclaimer"
import {
  OrderSummary,
  OrderSummaryProps,
} from "src/components/Account/BillingPortal/ChangePlan/OrderSummary/OrderSummary"
import { PlanSelect } from "src/components/Account/BillingPortal/ChangePlan/PlanSelect"
import { UpdateSubscriptionButton } from "src/components/Account/BillingPortal/ChangePlan/UpdateSubscriptionButton"
import {
  getCouponIdsFromEstimate,
  getUpdateOptions,
} from "src/components/Account/BillingPortal/ChangePlan/utils"
import {
  calculatePlanChangeType,
  PlanChangeTypeV2,
  PlanWithCostEstimate,
  stringToPlan,
} from "src/components/Account/BillingPortal/ChangePlan/utils"
import {
  IPaymentSource,
  TEstimateBreakdown,
} from "src/components/Account/types"
import { usePostUpdateSubscriptionEstimate } from "src/data/billing/queries/billingQueries"
import {
  TCurrentSubscription,
  TSubscriptionCurrencyCode,
} from "src/data/billing/types/billingTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchSubscriptionUpdateEstimate } from "src/data/subscription/queries/subscriptionQueries"
import { useHomeTokens } from "src/hooks/useHomeTokens"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MBanner } from "src/ui/MBanner/MBanner"
import { spacing } from "src/ui/spacing"
import { Optional } from "src/utils/tsUtil"

export function ChangeSubscription({
  selectedPlan,
  setSelectedPlan,
  paymentSource,
  currencyCode,
  plansWithEstimate,
  subscription,
  discountCode,
}: {
  selectedPlan: SelectedPlan | undefined
  setSelectedPlan: Dispatch<SetStateAction<SelectedPlan | undefined>>
  paymentSource: IPaymentSource | undefined
  currencyCode: TSubscriptionCurrencyCode
  plansWithEstimate: PlanWithCostEstimate[]
  subscription: TCurrentSubscription
  discountCode?: string
}) {
  const { navigate } = useRouter()
  const { t, langKeys } = useTranslate()
  const { hasHomeTokenRole } = useHomeTokens()
  const { orgId } = useOrganization()

  const postUpdateSubscriptionEstimate = usePostUpdateSubscriptionEstimate()
  // Aliasing the mutate fuction to be able to use it in a dep array without
  // getting eslint warnings
  const postUpdateSubscriptionEstimateMutate =
    postUpdateSubscriptionEstimate.mutate

  const planId = selectedPlan?.planId

  const fetchSubscriptionUpdateEstimate = useFetchSubscriptionUpdateEstimate({
    orgId,
    body: {
      coupon_ids: discountCode ? [discountCode] : undefined,
      new_plan_id: planId ?? "no-plan",
    },
    options: { enabled: !!planId && hasHomeTokenRole },
  })
  const htEstimate = fetchSubscriptionUpdateEstimate.data

  const planChangeType = calculatePlanChangeType({
    subscription,
    planId: selectedPlan?.planId,
    currentPlan: stringToPlan(subscription?.plan),
    newPlan: selectedPlan?.plan,
    isLegacyPlan: !!subscription?.legacy_plan,
  })

  /**
   * TODO WEB-589: Refactor discount code handling in change plan
   *
   * Currently the discount code is propagated down to the estimation
   * and update queries differently based on if it's a new subscription being
   * created, or if it's an existing one. We should look at aligning this.
   * We should also look at making this call a bit more stable, since the
   * discount code could fail at being applied, at which point we shouldn't
   * force reset the term, and instead show some error */
  const fetchSubscriptionEstimate = useCallback(
    ({
      selectedPlan,
      planChangeType,
    }: {
      selectedPlan: SelectedPlan
      planChangeType: PlanChangeTypeV2
    }) => {
      const updateOptions = getUpdateOptions({
        planChangeType,
        hasCouponIds: !!discountCode,
      })

      postUpdateSubscriptionEstimateMutate({
        currentSubscription: subscription,
        updateBody: {
          subscription: {
            plan_id: selectedPlan.planId,
            id: subscription.subscription_id,
          },
          replace_addon_list: false,
          coupon_ids: discountCode ? [discountCode] : undefined,
          ...updateOptions,
        },
      })
    },
    // Disabling due to the mutate function not being picked up by eslint
    // and just passing the mutation object will make rerender whenever it
    // posts something, which we don't want.
    [postUpdateSubscriptionEstimateMutate, subscription, discountCode]
  )

  // Fetch subscription estimate based on the currently selected plan
  useEffect(() => {
    if (
      hasHomeTokenRole ||
      !selectedPlan ||
      planChangeType === undefined ||
      planChangeType === PlanChangeTypeV2.UNCHANGED
    ) {
      return
    }

    fetchSubscriptionEstimate({ selectedPlan, planChangeType })
  }, [
    selectedPlan,
    fetchSubscriptionEstimate,
    planChangeType,
    hasHomeTokenRole,
  ])

  const selectedPlanWithEstimate = plansWithEstimate.find(
    (plan) => plan.availablePlan.plan_id === selectedPlan?.planId
  )

  const hideSummary =
    !selectedPlan || planChangeType === PlanChangeTypeV2.UNCHANGED
  const estimate = hasHomeTokenRole
    ? htEstimate?.estimate
    : postUpdateSubscriptionEstimate.data?.estimate
  const estimateLoading =
    fetchSubscriptionUpdateEstimate.isInitialLoading ||
    postUpdateSubscriptionEstimate.isLoading

  return (
    <>
      {subscription &&
        subscription.legacy_plan &&
        subscription.subscription_status !== "cancelled" && (
          <LegacyPlanDisclaimer subscription={subscription} />
        )}

      <PlanSelect
        plans={plansWithEstimate}
        currencyCode={currencyCode}
        selectedPlan={selectedPlan}
        onSelect={(plan: SelectedPlan) => setSelectedPlan(plan)}
        currentPlan={subscription}
      />

      <Summary
        hidden={hideSummary}
        loading={estimateLoading}
        estimate={estimate}
        breakdown={htEstimate?.breakdown}
        planCost={selectedPlanWithEstimate?.cost}
        currencyCode={currencyCode}
        selectedPlan={selectedPlan}
      />

      {!paymentSource && (
        <MBanner
          type="warning"
          style={{ marginBottom: spacing.M, textAlign: "center" }}
        >
          {t(langKeys.change_plan_add_payment_method_first)}
        </MBanner>
      )}

      <UpdateSubscriptionButton
        selectedPlan={selectedPlan}
        planChangeType={planChangeType}
        subscription={subscription}
        onSuccess={() => navigate(Routes.ChangePlanSuccess.location())}
        disabled={!paymentSource || postUpdateSubscriptionEstimate.isLoading}
        couponIds={getCouponIdsFromEstimate(
          postUpdateSubscriptionEstimate.data?.estimate
        )}
      />
    </>
  )
}

function Loading() {
  return (
    <div>
      <Skeleton width="30%" />
      <Skeleton width="50%" />
      <Skeleton width="85%" />
      <Skeleton width="45%" />
      <Skeleton width="100%" />
    </div>
  )
}

function Summary({
  loading,
  estimate,
  breakdown,
  planCost,
  currencyCode,
  selectedPlan,
  hidden,
}: {
  loading: boolean
  hidden: boolean
  breakdown: Optional<TEstimateBreakdown>
} & Partial<OrderSummaryProps>) {
  if (loading && !hidden) {
    return <Loading />
  }

  if (!estimate || !planCost || !currencyCode || !selectedPlan || hidden) {
    return null
  }

  return (
    <OrderSummary
      estimate={estimate}
      breakdown={breakdown}
      planCost={planCost}
      selectedPlan={selectedPlan}
      currencyCode={currencyCode}
    />
  )
}
